import { Icon, LinkText, Paragraph } from '@mediahuis/chameleon-react';
import { SocialX, MailFill, Instagram, Facebook, PhoneFill } from '@mediahuis/chameleon-theme-wl/icons';
import cx from 'classnames';
import { ReactElement } from 'react';

import styles from './author-page.module.scss';

type AuthorSocialBrand = 'email' | 'twitter' | 'facebook' | 'instagram' | 'phone';
type AuthorSocialProps = {
  href?: string | null;
  brand: AuthorSocialBrand;
  caption?: string | null;
};

const Icons: Record<AuthorSocialBrand, ReactElement> = {
  email: MailFill,
  twitter: SocialX,
  facebook: Facebook,
  instagram: Instagram,
  phone: PhoneFill,
};

const Prefixes: Partial<Record<AuthorSocialBrand, string>> = {
  twitter: '@',
  instagram: '/',
  facebook: '/',
};

export function AuthorSocial({ href, brand, caption }: AuthorSocialProps) {
  if (!caption || !href) {
    return null;
  }

  return (
    <div className={cx(styles.socialMediaItem, styles[brand])}>
      <LinkText className={styles.socialMediaItemLink} href={href} target="_blank">
        <Icon className={styles.icon} as={Icons[brand]} size="sm" appearance="default" />
        <Paragraph className={styles.socialMediaHandle}>
          {Prefixes[brand]}
          {caption}
        </Paragraph>
      </LinkText>
    </div>
  );
}
